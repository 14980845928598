@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "primeicons/primeicons.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&display=swap');
// @import url('https://fonts.cdnfonts.com/css/freestyle-script');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import './styles/component/header.scss';
@import '@ctrl/ngx-emoji-mart/picker';
@import './styles/fonts';

body {
    font-family: 'Inter', 'sans-serif' !important;
    background-color: #f2f4f7;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
}

table {
  font-family: 'Inter', 'sans-serif' !important;
}

//----------------------------Custom scroll-------------------------
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
}

//*****************************************************
//*************************Login **************************
.flex-container {
    display: flex;
    flex: 90%;
    align-items: center;
}

.login-container {
    @apply flex flex-col w-full gap-5 mt-8;
    .login-username-input {
        @apply w-full min-h-[56px] border rounded-lg;
    }
    .sign-in-btn {
        @apply w-full bg-branding-primary-700 border border-branding-primary-300 rounded-lg;
        .p-button:hover {
            @apply bg-inherit;
        }
    }
    .sign-in-btn:hover {
        @apply bg-branding-primary-600;
    }
    .sign-in-btn:focus {
        box-shadow: none !important;
    }
    .p-inputtext:focus {
        @apply border-branding-primary-300 outline-none;
        box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }
    .p-inputtext:hover {
        @apply border-branding-primary-300;
    }
    .p-button.p-button-secondary:enabled:focus {
        box-shadow: unset;
    }
    .login-phone-dropdown {
        @apply ml-2.5;
        .p-dropdown-panel {
            @apply w-[260px] bg-white;
        }
        .p-overlay {
            @apply mt-4 ml-[-12px];
        }
        .p-dropdown {
            @apply flex items-baseline;
        }
        .p-component {
            @apply border-none h-10;
        }
        .p-dropdown-items-wrapper {
            @apply bg-white text-sm min-h-[240px] border-system-gray-200;
        }
    }
    .password-input::placeholder {
        @apply text-xs text-system-gray-500;
    }
    .password-input::-ms-reveal {
        @apply hidden;
    }
    .login-phone-input {
        @apply border-none w-full;
    }
}

//************************************************************
//***************************Sign up*********************************
.background {
    @apply h-auto bg-cover object-contain flex;
    background-image: url('/assets/images/Background.svg');
}

.container {
    @apply hidden;
}

.sign-up-flex-container {
    @apply hidden;
}

.sign-up-container {
    flex: 1;
    display: flex;
    align-items: flex-start;
}

.flex-container {
    display: flex;
    flex: 90%;
    align-items: center;
    justify-content: flex-end;
}

.sign-up-password-input::placeholder {
    @apply text-xs text-system-gray-400;
}

.sign-up-password-input::-ms-reveal {
    @apply hidden;
}

.area-input {
    @apply border-none w-full;
}

.verification-form {
    @apply h-12 flex flex-row justify-start items-start gap-4 p-0;
    .p-inputtext::placeholder {
        @apply text-center text-base;
    }
}

.verification-input {
    @apply h-12 w-14 text-center font-bold;
}

.resend-button {
    @apply bg-white border-none cursor-pointer font-bold text-branding-primary-700;
}

.sign-up-btn {
    @apply w-full bg-branding-primary-700 border border-branding-primary-300 rounded-lg;
    .p-button:hover {
        @apply bg-inherit;
    }
    .p-button:enabled:active {
        @apply border-transparent bg-branding-primary-700;
    }
}

.p-inputtext {
    @apply w-full min-h-[48px] rounded-lg;
}

.p-inputtext:focus {
    border-color: #84caff;
    outline: none;
    box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.p-inputtext:hover {
    @apply border-branding-primary-300;
}

.sign-up-form {
    @apply flex flex-col gap-4 mt-5;
    .policy-checkbox {
        .p-checkbox .p-checkbox-box {
            @apply rounded-lg;
        }
    }
    .area-dropdown {
        @apply ml-2.5;
        .p-dropdown-panel {
            @apply w-[260px] bg-white;
        }
        .p-overlay {
            @apply mt-4 ml-[-12px];
        }
        .p-dropdown {
            @apply flex items-center;
        }
        .p-component {
            @apply border-none h-10;
        }
        .p-dropdown-items-wrapper {
            @apply bg-white text-sm min-h-[240px] border-system-gray-200;
        }
    }
    .flex-input {
        @apply flex flex-col gap-1;
        input {
            @apply w-full;
        }
    }
    .p-inputtext:focus {
        border-color: #84caff;
        outline: none;
        box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }
    .p-inputtext:hover {
        @apply border-branding-primary-300;
    }
}

@media (min-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
    }
    .sign-up-container {
        flex: 1.25;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}

//************************************************************
.booking-menu {
     ::-webkit-scrollbar {
        width: 7px !important;
    }
}

.range-picker .p-calendar .p-datepicker {
    border: none;
    padding: 0;
}

.range-picker .p-calendar .p-datepicker .p-datepicker-header {
    border-bottom: none !important;
    padding: 0;
    .p-overlaypanel.p-component {
        left: 0px !important;
    }
}

.range-picker .p-datepicker table td {
    padding: 5px 5px !important;
    .p-highlight {
        background: #1570EF;
        color: #FFFFFF;
    }
}

.p-datepicker table td.p-datepicker-today>span {
    border: 1.5px solid #1570EF;
}

.range-picker .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: none;
}

.range-picker .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next.p-datepicker-next:focus,
.range-picker .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next.p-datepicker-prev:focus {
    box-shadow: none !important;
}

// -------------------------------****------------------------------------
// -----------------------------Header------------------------
.assistant-btn {
    background: linear-gradient(98.52deg, #fead34 4.54%, #ff7041 87.05%);
    backdrop-filter: blur(2px);
    border-radius: 6px;
}

.box-icon {
    min-height: 20px;
    min-width: 20px;
    max-width: 20px;
    min-width: 20px;
}

.box-avatar {
    background: linear-gradient(white, white) padding-box, linear-gradient(98.52deg, #fead34 4.54%, #ff7041 87.05%) border-box;
    border-radius: 50em;
    border: 2px solid transparent;
}

.add-destination button.p-button {
    border: none;
    height: 52px !important;
}

#container-search .p-button {
    border: none !important;
    background: none !important;
}

.p-overlaypanel .p-overlaypanel-content {
    padding: 0 !important;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
    display: none;
}

@media screen and (max-width: 1450px) {
    .text-assistant,
    .box-nickname {
        display: none;
    }
}

.search-input {
    --tw-bg-opacity: 0, transition: width 0.3s ease-in-out !important;
    --webkit-transition: width 0.3s ease-in-out;
}

.tooltip-menu .p-tooltip-text {
    font-size: 13px !important;
}

.tooltip-menu.p-tooltip .p-tooltip-text {
    @apply bg-black bg-opacity-40 font-medium text-sm text-gray-200 mt-3
}

.tooltip-menu .p-tooltip-arrow {
    display: none;
}

.shadow-xs {
    box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

#menu-bar .search-box-default {
    height: 40px;
}

#menu-bar .search-box-searching {
    border: 1px solid #84CAFF;
    box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

#menu-bar .search-box {
    border-radius: 22px;
    --tw-bg-opacity: 0, transition: width 0.3s ease-in-out !important;
    --webkit-transition: width 0.3s ease-in-out;
}

#menu-bar .search-box-header {
    width: 100%;
    height: 40px;
    border: 1px solid #eaecf0;
    border-radius: 50px;
    --tw-bg-opacity: 0, transition: width 0.3s ease-in-out !important;
    --webkit-transition: width 0.3s ease-in-out;
}

// -------------------------------------*********--------------------------
// ---------------------------------filter--------------------------------
.search-box .p-input-icon-left {
    width: 100%;
}

.search-box .p-overlaypanel {
    margin-top: 59px;
    left: 0 !important;
    top: 0 !important;
    z-index: 1 !important;
}

.search-box .p-overlaypanel::before,
.search-box .p-overlaypanel:before {
    display: none !important
}

.p-overlaypanel:after {
    display: none;
}

// -------------------------------------*********--------------------------
// ------------------------------------ slideshow--------------------------
.box-slideshow .p-carousel-content {
    position: relative;
}

.box-slideshow .p-carousel-content ul.p-carousel-indicators {
    position: absolute;
    bottom: 0;
    width: 100%;
    align-items: center;
    padding: 2px !important;
}

.box-slideshow .p-carousel-content ul.p-carousel-indicators li.p-carousel-indicator button {
    height: 4px !important;
    width: 16px;
    background-color: white;
    border-radius: 2px !important;
    padding: 0 5px 0 5px;
}

.box-slideshow .p-carousel-content ul.p-carousel-indicators li.p-carousel-indicator.p-highlight button {
    background-color: #0166ff !important;
    width: 25px !important;
}

.box-slideshow .p-carousel-container {
    position: relative;
}

.box-slideshow .p-carousel .p-carousel-content .p-carousel-prev {
    position: absolute;
    z-index: 10;
    // top: 0;
    left: 12px;
    height: 40px;
    width: 40px;
    color: white;
    background: rgba(16, 24, 40, 0.4);
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0;
    // gap: 8px;
}

.box-slideshow .p-carousel .p-carousel-content .p-carousel-next {
    position: absolute;
    right: 12px;
    // top: 0;
    z-index: 10;
    height: 40px;
    width: 40px;
    color: white;
    background: rgba(16, 24, 40, 0.4);
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;
    margin: 0;
}

.box-slideshow .p-carousel .p-carousel-content .p-carousel-next:focus {
    box-shadow: none;
}

.box-slideshow .p-carousel .p-carousel-content .p-carousel-prev:focus {
    box-shadow: none;
}

.box-slideshow .p-carousel .p-carousel-content {
    .p-carousel-next,
    .p-carousel-prev {
        padding: 0;
        text-align: center;
    }
    .p-carousel-next .pi-chevron-right,
    .p-carousel-prev .pi-chevron-left {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
}

.tourist-spot .hide .p-carousel-content .p-carousel-prev {
    display: none;
}

.tourist-spot .show .p-carousel-content .p-carousel-next,
.tourist-spot .show .p-carousel-content .p-carousel-prev,
.tourist-spot .hide .p-carousel-content .p-carousel-next {
    color: gray;
    background: white;
    height: 40px;
    width: 40px;
    //top: 29%;
    //top:50%;
    transform: translateY(-50%);
}

.tourist-spot .p-carousel .p-carousel-content {
    .p-carousel-next,
    .p-carousel-prev {
        padding: 0;
        text-align: center;
    }
    .p-carousel-next .pi-chevron-right,
    .p-carousel-prev .pi-chevron-left {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
}

//------------------------------------ DateRangePicker--------------------------
.theme-green .bs-datepicker-head {
    background-color: #FFFFFF;
}

bs-datepicker-container,
bs-daterangepicker-container {
    z-index: 1001 !important;
}

bs-month-calendar-view {
    padding: 16px;
}

bs-years-calendar-view {
    padding: 16px;
}

bs-days-calendar-view:first-of-type {
    border-right: 1px solid #EAECF0;
}

.bs-datepicker-body table.months {
    padding: 0px;
}

.bs-datepicker-body table th {
    color: var(--gray-700, #344054);
    text-align: center;
    font-size: 14px;
    font-family: Inter;
    line-height: 20px;
    font-style: normal;
    font-weight: 500;
    padding: 10px 5px;
}

.bs-datepicker-head button:hover {
    padding: 0px 10px 0px 10px !important;
}

.search-map {
    .bs-datepicker {
        left: 52px;
    }
}

.bs-datepicker {
    border-radius: 8px;
    bottom: 8px;
}

.bs-datepicker-body table.days td.active:not(.select-start):before,
.bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
    background: #F2F4F7;
}

.bs-datepicker-head button {
    color: black;
}

.bs-datepicker-body table td span.selected {
    background-color: #1570EF;
    color: #FFFFFF !important;
    border-radius: 8px !important;
}

.bs-datepicker-body table td {
    padding: 1px;
}

.bs-datepicker-body table td span {
    height: 35px;
    width: 100px;
}

.bs-datepicker-body table.days span.is-highlighted.in-range:before {
    right: 10px;
    left: -5px;
}

.bs-datepicker-body table.days span.in-range.select-end:before {
    content: "";
    left: -10px;
    top: 6px;
    bottom: 6px;
    right: 21px;
}

bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
    content: "";
    bottom: 6px;
    left: -8px;
    right: -5px;
    top: 6px;
}

.bs-datepicker-container {
    padding: 0px;
}

.bs-datepicker-head {
    min-width: auto;
    height: 25px;
    padding: 0px;
}

bs-days-calendar-view:first-of-type {
    border-right: 1px solid #EAECF0;
}

bs-days-calendar-view {
    width: auto;
    padding: 24px 16px;
    height: 392px;
}

.bs-datepicker-body table td span.disabled {
    color: #9aaec1 !important;
}

.p-overlaypanel-content {
    padding: 0px !important;
}

.bs-datepicker-body {
    border: none;
    padding: 0px;
}

.bs-datepicker-body:first-child {
    border-left: 1px solid;
}

.ng-trigger.ng-trigger-animation {
    border-radius: 8px;
    transform-origin: top center !important;
}

.search-hotel button {
    position: relative;
    padding: 16px;
    top: 0px;
    left: 0px !important;
    border-radius: 12px !important;
    height: 52px;
}

.search-hotel button span {
    position: relative;
    bottom: 1px;
    left: 0px;
    font-weight: 500;
}

.customTodayClass {
    border-radius: 8px !important;
    border: 1px solid #1570EF;
}

.containerClassCalendar .bs-datepicker-body table td span.selected:after {
    content: "";
    left: 20px;
    right: 15px;
    background: #F2F4F7;
}

.containerClassCalendar .bs-datepicker-body table td span.selected:before {
    content: "";
    left: 25px;
    right: 15px;
    bottom: 20px;
    top: 20px;
    background: #F2F4F7;
}

.containerClassCalendar .bs-datepicker-body table td span {
    text-align: center;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.bs-datepicker-body .days.weeks {
    height: 310px;
}

.p-inputtext:enabled:focus {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 8px;
    border: 1px solid #84CAFF !important;
    background: var(--base-white, #FFF);
    box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
}

.p-input-icon-left>i:first-of-type {
    top: 23px
}

.bs-datepicker-head button.current {
    font-weight: 600;
}

.bs-datepicker-body table.days span {
    color: var(--gray-700, #344054);
    width: 40px;
    height: 40px;
    line-height: 40px;
}

.bs-datepicker-head button[disabled],
.bs-datepicker-head button[disabled]:hover {
    color: #D0D5DD;
    background-color: none !important;
}

.buttonText button {
    width: 185px !important;
    max-width: 100%;
    left: 0 !important;
    margin: -7px 0px 0px 0px;
    padding: 0 !important;
    top: 7px !important;
}

.buttonText button span {
    top: 0px !important;
}

.search-available {
    margin-top: 0 !important;
}

@media screen and (min-width: 320px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 8%;
        }
        .p-carousel-next {
            right: 8%;
        }
    }
}

@media screen and (min-width: 375px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 10%;
        }
        .p-carousel-next {
            right: 10%;
        }
    }
}

@media screen and (min-width: 425px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 3%;
        }
        .p-carousel-next {
            right: 3%;
        }
    }
}

@media screen and (min-width: 768px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 6%;
        }
        .p-carousel-next {
            right: 6%;
        }
    }
}

@media screen and (min-width: 810px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 3%;
        }
        .p-carousel-next {
            right: 3%;
        }
    }
}

@media screen and (min-width: 1024px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 5%;
        }
        .p-carousel-next {
            right: 5%;
        }
    }
    #addressPanel {
        width: 652px !important;
    }
}

@media screen and (min-width: 1200px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 3%;
        }
        .p-carousel-next {
            right: 3%;
        }
    }
}

@media screen and (min-width: 1440px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 2%;
        }
        .p-carousel-next {
            right: 2%;
        }
    }
}

@media screen and (min-width: 1680px) {
    .tourist-spot .p-carousel .p-carousel-content {
        .p-carousel-prev {
            left: 2.5%;
        }
        .p-carousel-next {
            right: 2.5%;
        }
    }
}

@media screen and (max-width: 991px) {
    #container-search {
        height: 100% !important;
        padding: 4px 8px 6px 8px !important;
        .search-hotel {
            top: -16px !important;
            left: 0px !important;
        }
    }
    #container-search .p-button {
        width: 100% !important;
        padding: 10px;
        position: relative;
    }
    .search-hotel {
        padding: 15px 0px;
        left: 0px !important;
    }
    .p-overlaypanel .p-overlaypanel-content {
        width: auto;
    }
    .bs-datepicker-body:first-child {
        border: none;
    }
    .search-box .p-overlaypanel {
        width: 100%;
    }
    .bs-daterangepicker-container {
        width: 75% !important;
    }
    .search-hotel button {
        top: 14px !important;
        left: 0px !important;
        border-radius: 12px !important;
    }
    .dropdown-age .p-dropdown {
        width: 100% !important;
    }
    .container-button {
        max-width: 100% !important;
        margin-top: 15px;
    }
    #container-search .container-place {
        top: 0px !important;
    }
    .container-select-date {
        top: 6px !important;
        margin-top: -6px !important;
    }
    #container-search #selectPeople {
        top: 2px !important;
        margin-top: 6px;
    }
    .search-hotel button span {
        left: 0;
    }
    .search-map {
        .search-hotel button {
            top: 12px !important;
        }
    }
}

@media (min-width:992px) and (max-width:1023px) {
    .containerClassCalendar {
        left: 15px;
        top: 2px;
    }
    .bs-datepicker {
        transform-origin: top center !important;
    }
    .search-hotel button span {
        left: 0;
    }
    #container-num-room {
        width: 100%;
    }
    #container-search .p-button {
        width: 100% !important;
        padding: 14px;
        position: relative;
        top: 13px;
        height: 50px !important;
    }
    .search-hotel {
        padding: 16px 0px;
        border-radius: 12px;
        left: 0px !important;
        top: -13px;
    }
    .search-hotel button {
        top: 11px;
        border-radius: 8px !important;
    }
    .p-overlaypanel .p-overlaypanel-content {
        width: auto !important;
    }
    .ng-trigger.ng-trigger-animation {
        width: 100%;
    }
    .search-hotel button {
        padding: 12px;
        left: 0px !important;
    }
    .dropdown-age .p-dropdown {
        width: 100% !important;
    }
    .container-button {
        max-width: 100% !important;
    }
    .buttonText button {
        top: 15px !important;
    }
    .search-map .search-hotel {
        top: -14px !important;
    }
    #container-search {
        height: 60px !important;
        gap: 2px !important;
        #selectPeople {
            top: 8px !important;
            width: 180px !important;
            max-width: fit-content !important;
        }
        .container-place {
            top: 7px;
            width: max-content !important;
        }
        .search-hotel {
            top: -16px;
            display: math;
            left: 1px !important;
        }
    }
    .search-box .p-input-icon-left {
        width: 100%;
        top: 1px;
        left: -3px
    }
    .container-select-date {
        top: 12px !important;
    }
    #addressPanel {
        width: 500px !important;
    }
    .search-box .p-overlaypanel {
        width: auto !important;
    }
    #container-num-room {
        width: 332px !important;
    }
    .search-map {
        .p-button {
            top: 11px !important;
        }
    }
    .search-available {
        .search-hotel {
            top: -8px !important;
        }
        .buttonText button {
            padding: 15px 6px 15px 6px !important;
            top: 14px !important;
        }
        .buttonText button span {
            top: 0px !important;
        }
        .search-hotel button {
            border-radius: 12px !important;
        }
    }
    .bs-datepicker {
        left: 160px;
    }
}

@media (min-width:1024px) and (max-width: 1279px) {
    .container-place {
        top: 9px !important;
        width: 330px !important;
    }
    .container-select-date {
        top: 12px !important;
        margin: 0px 5px;
    }
    .container-select-people {
        top: 8px !important;
        width: 330px !important;
    }
    .container-button {
        top: 8px !important;
    }
    .view-detail-search-bar,
    .search-view-content-search-bar {
        .search-hotel {
            display: inline-table;
            top: -4px;
            left: -3px !important;
        }
    }
    .search-hotel {
        display: inline-table;
        top: -11px;
        left: -3px !important;
    }
    .buttonText {
        height: 52px !important;
        top: -12px !important;
    }
    .search-hotel button {
        padding: 15px !important;
        left: 1px !important;
    }
    .search-hotel button span {
        left: 0;
    }
    .search-available {
        .search-hotel button {
            padding: 10px !important;
            left: 0 !important;
        }
        .buttonText button span {
            top: 0px !important;
        }
    }
    .bs-datepicker {
        left: 145px;
    }
}

.search-address-list::-webkit-scrollbar {
    width: 4px !important;
    border-radius: 8px;
}

.icon-maker-pin {
    border-radius: 20px;
    background: #D1E9FF;
}

//---------------------------------------dropdown Age ---------------------
.p-dropdown .p-dropdown-label.p-placeholder {
    color: #475467;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0px 32px 0px 0px;
    position: relative;
}

.dropdown-age .p-dropdown span::before {
    color: #475467;
    font-size: 14px;
    font-style: normal;
    font-family: primeicons;
    font-weight: 500;
    line-height: 20px;
}

//'primeicons'
.p-dropdown .p-dropdown-trigger {
    width: 1.25rem !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    height: 44px !important;
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0px !important;
}

.p-dropdown-items-wrapper {
    max-height: 352px !important;
}

.dropdown-age .p-dropdown span {
    padding: 10px 0px 10px 0px;
    text-align: center;
    color: #475467;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.dropdown-age .p-dropdown {
    height: 40px !important;
    width: 137px;
    border-radius: 6px;
}

.p-dropdown:not(.p-disabled).p-focus {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 8px;
    border: 1px solid #84CAFF !important;
    background: var(--base-white, #FFF);
    box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #84CAFF;
}

// -------------------------------------*********--------------------------
// ------------------------------------- Footer-------------------------------
.footer-section {
    .p-button {
        border: none;
        width: 100%;
        padding: 10px 18px;
        height: 100%;
        font-weight: 600;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .p-button:hover {
        background: #1570EF;
        border-color: #1570EF;
    }
    .footer-section .p-button:hover {
        background-color: #1570EF;
    }
    .p-button-label {
        font-weight: 600;
        font-size: 1rem;
    }
    @media screen and (max-width: 1720px) {
        .p-button-label {
            font-size: 0.8rem;
        }
    }
    @media screen and (max-width: 1400px) {
        .p-button-label {
            font-size: 1rem;
        }
    }
    .p-component {
        font-family: "Inter";
    }
    .p-inputtext.ng-dirty.ng-invalid {
        border-color: #ced4da;
    }
    .p-inputtext:hover {
        border-color: #ced4da;
    }
    .text-detail {
        color: rgba(52, 64, 84, 1);
        font-size: 16px;
        font-family: "Inter";
        line-height: 24px;
        font-weight: 400;
        font-style: normal;
    }
    .register-btn button {
        padding: 0 5 0 5 !important;
    }
    .register-btn button:focus {
        box-shadow: none !important;
    }
    .register-btn {
        backdrop-filter: blur(2px);
        border-radius: 6px;
        /* Text md/Semibold */
        border-radius: 8px;
        border: 1px solid var(border-branding-primary-600, #1570EF);
        background: #1570EF;
    }
}

// -------------------------------------*********--------------------------
// ------------------------------------- continue-search -------------------------------
.continue-search {
  .ls-history {
      .p-carousel-container{
          position: relative;
          overflow: hidden;

          .p-carousel-next {
              position: absolute;
              z-index: 10;
              right: 0.5%;
              border: 1px solid #EAECF0;
              margin: 0 8px 0 0;
              background-color: white;
          }

          .p-carousel-prev {
              position: absolute;
              z-index: 10;
              left: 0.5%;
              margin: 0 0 0 8px;
              border: 1px solid #EAECF0;
              background-color: white;
          }

          .p-carousel-prev:hover, .p-carousel-next:hover {
              background-color: rgba(16, 24, 40, 0.3);
              color: white;
          }
      }
  }

  .hide-prev .p-carousel .p-carousel-content {
    .p-carousel-prev{
        display: none;
    }
    .p-carousel-next{
        display: flex;
    }
  }
  .hide-next .p-carousel .p-carousel-content {
    .p-carousel-next{
        display: none;
    }
    .p-carousel-prev{
        display: flex;
    }
  }
  .hide-all .p-carousel .p-carousel-content {
    .p-carousel-next, .p-carousel-prev {
        display: none;
    }
  }
  .history-search-item{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    &:hover {
      box-shadow: 1px 1px 10px 0 rgba(0,0,0,.1);
      transform: scale(0.95);
    }
  }
}

@media screen and (max-width: 8000px) {
    .continue-search p-carousel .p-carousel-container .p-carousel-item {
        flex: 1 0 32.15% !important;
    }
    .continue-search p-carousel .p-carousel-container .p-carousel-item-active {
        margin-right: 1.8%;
    }
    .ls-history .p-carousel-container {
        .p-carousel-next,
        .p-carousel-prev {
            height: 40px;
            width: 40px;
        }
    }
}

@media screen and (max-width: 1600px) {
    .continue-search p-carousel .p-carousel-container .p-carousel-item {
        flex: 1 0 32.2% !important;
        //max-width: 464px;
    }
    .continue-search p-carousel .p-carousel-container .p-carousel-item-active {
        margin-right: 1.6%;
    }
    .ls-history .p-carousel-container {
        .p-carousel-next,
        .p-carousel-prev {
            height: 40px;
            width: 40px;
        }
    }
}

@media screen and (max-width: 1440px) {
    .continue-search p-carousel .p-carousel-container .p-carousel-item {
        flex: 1 0 48% !important;
    }
    .continue-search p-carousel .p-carousel-container .p-carousel-item-active {
        margin-right: 4%;
    }
    .ls-history .p-carousel-container {
        .p-carousel-next,
        .p-carousel-prev {
            height: 28px;
            width: 28px;
        }
        .p-carousel-next .pi-chevron-right:before,
        .p-carousel-prev .pi-chevron-left:before {
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 767px) {
    .continue-search p-carousel .p-carousel-container .p-carousel-item {
        flex: 1 0 100% !important;
    }
    .continue-search p-carousel .p-carousel-container .p-carousel-item-active {
        margin-right: 4%;
    }
}

// -------------------------------------*********--------------------------
// ------------------------------------- famous-places -------------------------------
.p-tabview {
    .p-tabview-nav {
        //padding-left: 2px;
        padding-top: 8px;
        padding-bottom: 8px;
        background: transparent;
        border: none;
        height: fit-content;
        li.p-highlight {
            .p-tabview-nav-link {
                background: #fff1f3;
                color: #c01048 !important;
                border-color: none;
                i.places-nav-icon {
                    filter: none;
                }
            }
        }
        li {
            .p-tabview-nav-link {
                background: transparent;
                border: none;
                border-width: 0;
                padding: 0.25rem 0.75rem 0.25rem 0.625rem !important;
                margin-right: 0.5rem;
                font-weight: 500;
                --tw-bg-opacity: 1;
                --tw-text-opacity: 1;
                color: #344054;
                border-radius: 16px;
                background-color: var(--gray-200, #EAECF0);
                border-radius: 1rem
                /* 16px */
                ;
            }
            .p-tabview-nav-link:not(.p-disabled):focus {
                // box-shadow: inset 0 0 0 0.2rem #c7d2fe;
                box-shadow: none;
            }
            .p-tabview-nav-link:hover {
                // color: #c01048 !important;
                opacity: 0.8;
            }
        }
    }
    .p-tabview-panels {
        margin-top: 4px;
        background: transparent;
        padding: 0;
    }
}

.bs-datepicker-body table.months {
    padding: 0px;
}

// -------------------------------------*********--------------------------
//------------------------ Feature Hotel--------------------------
.hot-deal-section .grid,
.featured-hotel-section .grid {
    margin: 0px;
    margin-right: 0px !important;
}

//  ------------------------*************------------------------------------
// ------------------------------ filter-search-result -------------------------------
.section-filter-search-result {
    .view-map-btn {
        display: flex;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        border: 1px solid #1570EF;
        background: #1570EF;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
    .filter-btn {
        border: 2px solid #EAECF0;
        background-color: #fff;
    }
    .background-result-filter {
        border: 1px solid #EAECF0;
        background: #FFF;
    }
    .title-filter {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px
    }
    span.p-slider-range.ng-star-inserted {
        width: 254px;
    }
    .slider-view-map {
        .p-slider.p-slider-horizontal {
            width: 230px !important;
        }
    }
    .p-slider .p-slider-range {
        background: #2E90FA;
        box-shadow: none;
        width: 254px;
        height: 8px;
        border-radius: 6px;
    }
    .p-slider .p-slider-handle {
        border: 1.5px solid #2E90FA;
        box-shadow: none;
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
    .p-slider.p-slider-horizontal .p-slider-handle {
        margin-top: -12px;
    }
    .p-slider.p-slider-horizontal {
        height: 8px;
        width: 254px;
        border-radius: 6px;
    }
    .p-sidebar {
        width: 342px;
    }
    .p-slider .p-slider-handle:hover {
        background-color: #2E90FA;
        box-shadow: none;
    }
    span.p-checkbox-icon.pi.pi-check {
        width: 14px !important;
        height: 14px !important;
    }
    .p-slider:not(.p-disabled) .p-slider-handle:hover {
        background: #2E90FA;
        border: #2E90FA;
        box-shadow: none;
    }
    .p-sidebar .p-sidebar-content {
        padding: 16px !important;
    }
    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #2E90FA;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #2E90FA;
        background: #EFF8FF;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #1570EF;
        font-weight: 600;
        font-size: 14px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #EFF8FF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #2E90FA;
        box-shadow: none;
    }
    .itemFilter {
        color: #344054;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }
}

// --------------------------------------Sort hotel searching result---------------------------------
.hotel-sort .p-dropdown {
    width: 100%
}

.sort-by {
    .p-dropdown {
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        .p-dropdown-label {
            left: 0;
            top: 0;
            padding: 10px 0 10px 16px;
        }
        .p-inputtext {
            padding: 10px 16px;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            color: #344054;
        }
        .p-dropdown-items {
            width: 240px;
            position: absolute;
            right: 0;
            top: 5px;
            background-color: white;
            border-radius: 8px;
            overflow: hidden;
            z-index: 2 !important;
            border: 1px solid #EAECF0;
            box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        }
        span .show-icon {
            display: none;
        }
        .p-highlight {
            background-color: #F9FAFB !important;
            color: #344054 !important;
            font-weight: 600;
            border-radius: 6px;
            span .show-icon {
                display: inline;
            }
        }
        .p-dropdown-item {
            height: unset !important;
            margin: 2px 6px;
        }
        .p-dropdown-item:hover {
            background-color: #F9FAFB !important;
            border-radius: 6px;
        }
        .p-dropdown-trigger {
            width: unset !important;
            padding: 10px 16px 10px 0;
        }
        .pi-chevron-down {
            width: 20px;
            height: 20px;
        }
        .pi-chevron-down::before {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 20px;
        }
        .p-overlay {
            z-index: 3 !important;
        }
    }
}

// ----------------------------------********---------------------------
// ---------------------------------- Map View---------------------------
.leaflet-bottom.leaflet-right {
    display: none
}

.leaflet-left .leaflet-control-zoom {
    position: relative;
}

.leaflet-control-container {
    position: absolute;
    right: 0px !important;
    bottom: 0px !important;
}

.leaflet-left .leaflet-control-zoom {
    position: absolute;
    right: 24px !important;
    bottom: 24px !important;
}

.leaflet-top .leaflet-control {
    margin-top: 0px !important;
    margin-left: 0px !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    border: none !important;
    // position: relative;
}

.leaflet-touch .leaflet-bar a:first-child,
.leaflet-touch .leaflet-bar a:last-child {
    color: #344054 !important;
    border-radius: 8px !important;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leaflet-touch .leaflet-bar a:last-child {
    margin-top: 8px;
}

.leaflet-touch .leaflet-bar .leaflet-control-zoom-out span,
.leaflet-touch .leaflet-bar .leaflet-control-zoom-in span {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

// ----------------------------------********---------------------------
// ---------------------------------- Count Result ---------------------------
.count-result-section {
    border: 1px solid #EAECF0;
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px #1018280D;
    ;
    .btn-filter {
        border: 1px solid #D0D5DD;
        background-color: #fff;
        padding: 10px, 16px, 10px, 16px !important;
    }
}

// ------------------------------------- Hotel page room type ----------------------------------
.hotel-page-room-type {
    .divider {
        width: 100%;
        height: 1px;
        background-color: #EAECF0;
    }
    .btn-view-detail {
        height: 44px;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background-color: #fff;
        align-items: center;
    }
    .btn-buy-number {
        width: 119px;
        height: 44px;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        padding: 10px 18px;
        background-color: #fff;
        align-items: center;
    }
}

.hotel-page-room-type-selected {
    background: linear-gradient(112deg, #FFF 60.60%, #fff2ee 100%);
    border-color: #FF4405;
}

// --------------------------------------Sort hotel searching result---------------------------------
.available-rooms-list {
    background-color: #fff;
    border: 1px solid #EAECF0;
    border-radius: 12px;
}

// -------------------------------------*********--------------------------
// ------------------------------------- hotel-amenities -------------------------------
.hotel-amenities {
    background-color: #fff;
    border: 1px solid #EAECF0;
    box-shadow: 0px 1px 2px 0px #1018280D;
}

//--------------------------------------customer review--------------------
.customer-review {
    .field-checkbox {
        margin-bottom: 0 !important;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #2E90FA;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #2E90FA;
        background: #EFF8FF;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #1570EF;
        font-weight: 600;
        font-size: 14px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #EFF8FF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #2E90FA;
        box-shadow: none;
    }
    .p-progressbar-label {
        display: none !important
    }
    .p-progressbar .p-progressbar-value {
        background-color: #1570EF !important;
        border-radius: 8px;
    }
    // .p-progressbar, .ng-star-inserted {
    // background-color: #EAECF0 !important;
    // }
}

// -------------------------------------*********--------------------------
// ------------------------------------- custom image modal -------------------------------
.p-dialog .p-dialog-content {
    // background: none !important;
}

.p-dialog-content {
    padding: 0px !important;
}

.p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.show-image-modal .p-galleria-thumbnail-container {
    background: none;
    padding: 0px;
}

.p-dialog {
    max-height: 90% !important;
}

// -------------------------------------*********--------------------------
// ------------------------------------- Room details modal -------------------------------
.p-overflow-hidden,
.view-detail-header-search-result,
.view-detail-search-bar {
    // padding-right: 10px;
}

.p-overflow-hidden #menu-bar {
    // padding-right: 10px !important;
}

// -------------------------------------*********--------------------------
// ------------------------------------- Post modal -------------------------------
.custom-dropdown-post .p-dropdown {
    border: none;
    box-shadow: none;
}

.custom-dropdown-post .p-dropdown-trigger {
    height: 40px !important;
    justify-content: flex-start;
    display: flex;
    align-items: center;
}

.custom-dropdown-post .p-inputtext {
    max-height: 40px !important;
    min-height: 40px !important;
    padding: 0px !important;
    display: flex;
    align-items: center;
}

.custom-dropdown-post .p-dropdown {
    height: 40px !important;
}

.custom-dropdown-post .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0px;
}

.custom-dropdown-post .p-dropdown .p-dropdown-trigger {
    width: 2em !important;
    display: flex;
    align-items: center;
}

.custom-dialog {
    .p-dialog {
        border: none;
    }
    .p-dialog-header {
        border-bottom: none;
        background: linear-gradient(to top, #eff8ff 25.5%, #b1d5f3 100%);
        padding: 24px 24px 20px;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }
    .p-dialog-footer {
        border-top: none;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    .p-dialog-content {
        background: linear-gradient(to bottom, #eff8ff 10%, white 100%) !important;
        padding: 24px 24px 0;
    }
}

.background-opacity {
    border-radius: var(--border-radius-none, 0px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #F9FAFB 100%);
}

// -------------------------------------*********--------------------------
// ------------------------------------- Select destination modal -------------------------------
.custom-dialog-search-bar {
    .p-dialog {
        border: none;
    }
    .p-dialog-header {
        display: none;
    }
    .p-dialog-content {
        background: rgba(0, 0, 0, 0.15);
        padding: 24px 24px 0;
        display: flex;
        position: relative;
        justify-content: center;
        margin-right: 10px;
    }
    .p-dialog-content .box-search-bar {
        background: linear-gradient(to bottom, #d8ecfc 10%, white 100%) !important;
        position: absolute;
        top: 30%;
        display: flex;
        justify-content: center;
    }
}

.background-opacity {
    border-radius: var(--border-radius-none, 0px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #F9FAFB 100%);
}

// -------------------------------------*********--------------------------
// ---------------------------------- price-details -------------------------------
.price-detail {
    box-shadow: 0px 1px 2px 0px #1018280D;
    .p-dialog .p-dialog-header {
        border-bottom: 1px solid #EAECF0;
        height: 73px;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        color: #667085;
    }
    .p-inputswitch {
        width: 100%;
        height: 100%;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        left: 0.15rem;
        margin-top: -8px;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(1rem);
    }
    .soctrip-coins {
        border: 1px solid #D0D5DD;
        background: #FCFCFD;
    }
    .left-card-voucher {
        border: 1px solid #84CAFF;
        background: #EFF8FF;
        border-right: 1px dashed #84CAFF;
    }
    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-content {
        background: #fff !important;
        height: auto;
        padding-top: 12px !important;
        padding-bottom: 30px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .p-component-overlay {
        background-color: rgba(0, 0, 0, 0.4) !important;
    }
    .left-card-voucher-active {
        border: 1px solid #1570EF;
        background: #EFF8FF;
        border-right: 1px dashed #1570EF;
    }
    .left-card-voucher-inactive {
        border: 1px solid #D0D5DD;
        background: #F2F4F7;
        border-right: 1px dashed #D0D5DD;
    }
    .right-card-voucher {
        border: 1px solid #84CAFF;
        background: #FFF;
        border-left: none;
    }
    .right-card-voucher-avtive {
        border: 1px solid #1570EF;
        background: #FFF;
        border-left: none;
    }
    .right-card-voucher-inactive {
        border: 1px solid #D0D5DD;
        background: #FFF;
        border-left: none;
    }
    .confirm-voucher-btn {
        border: 1px solid #1570EF;
        background: #1570EF;
    }
    .cancel-voucher-btn {
        border: 1px solid #D0D5DD;
        background: #fff;
    }
    .promo-code-card {
        background: #F9FAFB;
        border: 1px solid #EAECF0;
        .input-promo-code {
            border: 1px solid;
            background: #fff;
        }
    }
    .text-total-price {
        background: linear-gradient(263.89deg, #FF692E, #FF4405);
        -webkit-background-clip: text;
        color: transparent;
    }
}

// ------------------------------------**********---------------------------
// ------------------------------------My booking details-----------------------------
.my-booking-details-section {
    .p-dialog {
        background: #fff !important;
        border-color: #EAECF0 !important;
        border-radius: 12px !important;
        max-height: 800px !important;
    }
    .p-dialog .p-dialog-content {
        padding: 0px 24px 0px 24px !important;
    }
    .p-dialog .p-dialog-footer {
        border-radius: 12px !important;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog-header {
        display: flex !important;
        align-items: flex-start !important;
        justify-content: space-between !important;
        flex-shrink: 0 !important;
        border-radius: 12px !important;
    }
}

// ------------------------------------**********---------------------------
// ------------------------------------Contact-info-----------------------------
.contact-info {
    .p-inputtext {
        padding: 10px 14px !important;
        border-radius: 8px !important;
    }
    .p-inputswitch {
        width: 36px;
        height: 20px;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        transform: translateX(-1px);
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(0.9rem);
    }
    .iti--separate-dial-code .iti__selected-flag {
        background-color: transparent !important;
        border-radius: 8px 0 0 8px;
        outline: none;
    }
    .iti.iti--allow-dropdown.iti--separate-dial-code.iti--show-flags {
        width: 100%;
    }
    .iti__country-list--dropup {
        margin-bottom: 6px !important;
    }
    .iti__country-list {
        margin-top: 6px !important;
    }
    .p-inputtext:enabled:hover {
        border-color: #ced4da !important;
    }
    .p-inputtext.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }
    .tel-input.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }
    .iti__arrow {
        margin: 0 12px 28px 4px;
        border-left: none;
        border-right: none;
        border-top: none;
    }
    .iti__selected-flag .iti__flag {
        margin-left: 3px;
        margin-right: 4px;
        scale: 1.35;
        border-radius: 4px;
    }
    .iti__flag {
        border-radius: 4px;
    }
    .iti__arrow--up {
        border-bottom: none;
        border-top: none;
    }
}

// -------------------------------------*********--------------------------
// ---------------------------------- booking-details -------------------------------
.booking-detail {
    box-shadow: 0px 1px 2px 0px #1018280D;
}

.booking-details-checkout-section {
    box-shadow: 0px 1px 2px 0px #1018280D;
}

// -------------------------------------View more image--------------------------
// -------------------------------------*********--------------------------
.albums-hotel .p-tabview-panels {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    padding-top: 10px;
}

.albums-hotel .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #175CD3 !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px;
    background: none !important
}

// -------------------------------------Available room list--------------------------
// -------------------------------------*********--------------------------
.available-rooms-list-section {
    .p-progress-spinner-svg {
        width: 80%;
        height: 80%;
    }
}

// -------------------------------------*********--------------------------
// ---------------------------------- search booking id modal -------------------------------
.search-booking-id-modal-section {
    .p-component-overlay {
        background-color: rgba(0, 0, 0.2, 0.6) !important;
    }
    .p-dialog .p-dialog-header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
    }
    .p-dialog .p-dialog-content {
        background: #fff !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        padding-left: 32px !important;
        padding-right: 32px !important;
    }
    .p-dialog-header {
        justify-content: center !important;
        position: relative;
    }
    .p-dialog .p-dialog-header-icons {
        position: absolute;
        right: 20px;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }
    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .iti--separate-dial-code .iti__selected-flag {
        background-color: #fff !important;
        border-radius: 8px 0 0 8px;
        outline: none;
    }
    .iti.iti--allow-dropdown.iti--separate-dial-code.iti--show-flags {
        width: 100%;
    }
    .iti__country-list--dropup {
        margin-bottom: 6px !important;
    }
    .iti__country-list {
        margin-top: 6px !important;
    }
    .p-inputtext:enabled:hover {
        border-color: #ced4da !important;
    }
    .p-inputtext.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }
    .tel-input.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }
}

.albums-hotel .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: none !important;
    color: #667085 !important;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.albums-hotel .p-tabview-nav li {
    height: 31px;
}

.albums-hotel .p-tabview-nav li:not(.p-highlight):not(.p-tabview-ink-bar) {
    border-bottom: solid 1px;
    @apply border-palette-gray-200
}

.albums-hotel .p-highlight.ng-star-inserted {
    border-bottom: 2px solid #175CD3;
}

.albums-hotel .p-tabview .p-tabview-nav {
    padding-top: 16px !important;
}

.albums-hotel .grid {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.albums-hotel .p-tabview .p-tabview-panels {
    margin-top: 16px !important;
}

.albums-hotel .p-tabview .p-tabview-panels {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

// -------------------------------------*********---------------------------------
//-----------------------------------------Map------------------------------------
.custom-marker-icon {
    display: flex;
    position: relative;
    background: #FFFFFF;
    height: 36px;
    width: max-content;
    padding: 4px 12px;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    border-radius: 8px;
    border: 2px solid #FF5316;
    .currency {
        color: #344054;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    .marker-text {
        color: #344054;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
}

.custom-marker-icon::before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 8px solid #FF5316;
}

.marker-text::before {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 7px solid #FFFFFF
}

.custom-marker-icon:hover {
    background-color: #FFD6AE;
    .marker-text::before {
        content: "";
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 7px solid #FFD6AE
    }
}

.customBox {
    position: absolute;
    transform: translate(-50%, -100%);
    z-index: 10001;
    height: 252px;
    display: flex;
    width: 452px;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin: 0;
}

.ranking-point {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: 49px;
    max-height: 32px;
    border-radius: 6px;
    border: 1px solid #1570EF;
}

.text-ranking-point {
    color: #1570EF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.text-feel {
    color: #344054;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.text-number-views {
    color: #667085;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.title-name-hotel {
    color: #101828;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.text-from {
    color: #344054;
    text-align: right;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.btn-view-available {
    border-radius: 8px;
    border: 1px solid #1570EF;
    background: #1570EF;
    color: #FFF;
    padding: 9px 18px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.selected .custom-marker-icon {
    background-color: #FF6327;
    .marker-text::before {
        content: "";
        border-top: 8px solid #FF6327
    }
}

.img-marker {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

// ************************************ OTA**********************
// ************************************ Left sidebar ************************************
.property-agency-info .left-sidebar {
    .p-timeline-event-opposite,
    .p-timeline-event-content {
        flex: none !important
    }
    .p-timeline-event-opposite,
    .p-timeline-event-content button {
        font-family: 'Inter', 'sans-serif' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
    .p-timeline-event {
        min-height: 60px !important;
    }
    .p-timeline-event-connector {
        display: none;
        flex-grow: 0;
        height: 20px;
        margin: auto;
    }
    .p-timeline .p-timeline-event-connector {
        background-color: #D0D5DD;
    }
    .p-timeline-event-opposite,
    .p-timeline-event-content {
        padding-left: 4px;
        padding-right: 0px !important
    }
}

// -------------------------------------Proerty amenities--------------------------
.property-amenities-ota-section,
.room-amenities-section {
    // title
    .p-dialog-title {
        font-family: 'Inter', 'sans-serif';
        font-weight: 600 !important;
        font-size: 18px;
        line-height: 28px;
    }

    //close icon
    .p-dialog-header-close-icon.pi {
        font-size: 20px;
    }

    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #D0D5DD;
        background-color: #F2F4F7;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #1570EF;
        background: #1570EF;
        color: #fff;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 4px !important;
    }
    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #fff;
        font-weight: 600;
        font-size: 12px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #1570EF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #1570EF;
        box-shadow: none;
    }
    .itemFilter {
        color: #344054;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }
    // Radio button
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
}

.property-amenities-ota-section-view-only {
    .top-amenities-checkbox {
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
    }
    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #D0D5DD;
        background-color: #F2F4F7;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #B2DDFF;
        background: #B2DDFF;
        color: #fff;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 4px !important;
    }
    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #fff;
        font-weight: 600;
        font-size: 12px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #B2DDFF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #B2DDFF;
        box-shadow: none;
    }
    .itemFilter {
        color: #344054;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }
    // Radio button
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #F2F4F7;
        &:hover {
            border-color: #F2F4F7 !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #fff;
        border-color: #B2DDFF;
        &:hover {
            background: #B2DDFF;
            border-color: #B2DDFF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #B2DDFF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #B2DDFF;
        background: #fff;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
}

// -------------------------------------*********--------------------------
@layer components {
    //Title at beginning component at homepage
    .title-hotel {
        @apply text-2xl font-semibold not-italic text-palette-gray-800 font-inter leading-[30px];
    }
    //Sub title at beginning component at homepage
    .sub-title-hotel {
        @apply text-[14px] font-normal not-italic text-palette-gray-500 font-inter leading-5 mt-[2px];
    }
    //Text view more at homepage
    .view-more {
        @apply text-base not-italic font-semibold text-palette-blue-700 font-inter;
    }
    //Common text with size 14 and font weight 500
    .text-soctrip-small {
        @apply text-sm not-italic font-medium font-inter leading-5;
    }
}

.popupCustomMapView {
    margin-bottom: 50px;
    .leaflet-popup-content-wrapper {
        padding: 0;
        .leaflet-popup-content {
            margin: 0;
            width: 292px !important;
        }
    }
    .leaflet-popup-tip {
        display: none;
    }
}

.popupCustomMapViewDetail {
    @extend .popupCustomMapView;
    margin-bottom: 70px;
}

.tooltipCustom.p-tooltip {
    .p-tooltip-text {
        background-color: #fff;
        color: #344054;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 600;
    }
}
// Styles light for tooltip
.t-tooltip-light {
    &:not(.no-min-width){
        @apply max-w-[25rem] min-w-60;
    }
  &.p-tooltip-top {
    .p-tooltip-arrow {
      @apply border-t-white;
    }
  }
  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      @apply border-b-white;
    }
  }
  &.p-tooltip-left {
    .p-tooltip-arrow {
      @apply border-l-white;
    }
  }
  &.p-tooltip-right {
    .p-tooltip-arrow {
      @apply border-r-white;
    }
  }
  .p-tooltip-text {
    @apply bg-white px-3 py-2 font-inter text-sm/4 font-medium text-gray-700;
  }
}
// ************************************ OTA**********************
// -------------------------------------General information --------------------------
.general-information-ota-section {
    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #D0D5DD;
        background-color: #F2F4F7;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #1570EF;
        background: #1570EF;
        color: #fff;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 4px !important;
    }
    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #fff;
        font-weight: 600;
        font-size: 12px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #1570EF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #1570EF;
        box-shadow: none;
    }
    .is-invalid .p-checkbox-box {
        border: 1px solid #dc3545;
    }
    .itemFilter {
        color: #344054;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }
    // Radio button
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
    // dropdown
    .p-dropdown {
        width: 100%;
    }
    .p-placeholder {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #98A2B3;
    }
    // muiti select
    p-multiselect {
        .p-disabled{
            color: #344054;
            background-color: #EAECF0 !important;
            opacity: 1 !important;
        }
    }
    .p-multiselect {
        width: 100%;
    }
    .p-multiselect:not(.p-disabled):hover {
        border-color: #D0D5DD;
    }
    .p-multiselect:not(.p-disabled).p-focus {
        box-shadow: none;
        color: #D0D5DD;
        border-color: #D0D5DD;
    }
    .p-multiselect-label {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        color: black;
        background-color: white;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }
    .p-inputtext:enabled:hover {
        border-color: #D0D5DD;
    }
    // input number
    .p-inputnumber,
    .p-inputwrapper {
        display: block;
    }
    p-inputnumber {
        &.is-disable {
            .p-inputnumber-input {
                color: #344054;
                background-color: #EAECF0 !important;
                opacity: 1 !important;
            }
        }
    }
}

// -------------------------------------Payment information --------------------------
.paymentInfo .p-overlay {
    width: 100%;
}

.paymentInfo {
    // Radio button
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
    // input text
    .p-inputtext:not(.p-inputtext::placeholder) {
        text-transform: uppercase;
    }
}

@media screen and (min-width: 1024px) {
    .paymentInfo .p-overlay {
        min-width: 245px !important;
    }
}

// **********************************************************
// ------------------------- Toast --------------------------
$bg_error: #FFFBFA;
$bd_error: #FDA29B;
$color_error: #B42318;
$bg_warning: #FFFCF5;
$bd_warning: #FEC84B;
$color_waring: #B54708;
$bg_success: #F6FEF9;
$bd_success: #6CE9A6;
$color_success: #027A48;
$bg_info: #FFF;
$bd_info: #D0D5DD;
$color_info: #344054;
$close_icon_error: '/assets//images//common-icon//error-close.svg';
$close_icon_warning: '/assets//images//common-icon//warning-close.svg';
$close_icon_success: '/assets//images//common-icon//success-close.svg';
$close_icon_info: '/assets//images//common-icon//info-close.svg';
.toast-booking {
    .p-toast {
        font-family: Inter !important;
        font-weight: 700 !important;
        opacity: 1 !important;
        top: 5.75rem;
        width: 29rem;
        right: 1.5rem;
        max-height: 17rem !important;
        overflow: hidden;
    }
    .p-toast-message-error {
        border: 1px solid $bd_error !important;
        background-color: $bg_error !important;
        opacity: 1 !important;
        color: $color_error !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_error) !important;
            background-repeat: no-repeat !important;
        }
    }
    .p-toast-message-warning {
        color: $color_waring !important;
        background-color: $bg_warning !important;
        border: 1px solid $bd_warning !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_warning) !important;
            background-repeat: no-repeat !important;
        }
    }
    .p-toast-message-success {
        color: $color_success !important;
        background-color: $bg_success !important;
        border: 1px solid $bd_success !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_success) !important;
            background-repeat: no-repeat !important;
        }
    }
    .p-toast-message-info {
        color: $color_info !important;
        background-color: $bg_info !important;
        border: 1px solid $bd_info !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_info) !important;
            background-repeat: no-repeat !important;
        }
    }
    .p-link:focus {
        box-shadow: none !important;
    }
    .p-toast-icon-close {
        height: 100% !important;
    }
    .p-toast-message-content {
        width: 100% !important;
        border: none !important;
        padding: 0 !important;
    }
    .p-toast-message {
        display: flex !important;
        box-shadow: none !important;
        border-radius: none !important;
        transition: none !important;
        height: fit-content !important;
        word-break: break-all !important;
        white-space: pre-wrap !important;
        width: 100%;
        padding: 1rem !important;
    }
    .p-toast-icon-close:hover {
        background: none !important;
    }
    .p-toast-icon-close-icon::before {
        content: none !important;
    }
    .toast-content {
        height: fit-content;
        line-height: 1.25rem;
        width: 100%;
        margin: 0 0.75rem;
        font-size: 0.875rem;
        word-break: break-word;
        white-space: pre-wrap;
    }
}

.ota-location-form {
    .p-inputtext {
        min-height: unset;
    }

    .p-dropdown .p-dropdown-label {
        color: #344054;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: center;
    }
    p-dropdown.p-element.p-inputwrapper {
        height: 44px;
    }
    .p-dropdown.p-component {
        min-width: 100%;
        min-height: unset;
        height: 44px;
        &.p-disabled {
            background-color: #EAECF0 !important;
            opacity: 1 !important;

            .p-dropdown-label.p-placeholder {
                opacity: .6 !important;
            }
        }
    }
    .p-dropdown .p-dropdown-label.p-placeholder {
        margin: 0 !important;
        height: 44px;
        min-height: unset;
        width: 1rem;
        color: #98A2B3;
    }
    .p-dropdown .p-dropdown-label .p-inputtext {
        align-items: center;
        width: 160px;
        min-height: 44px;
        height: 44px;
        font-size: 14px !important;
        font-family: 'Inter';
        outline: none;
        color: #344054 !important;
      }
    .p-dropdown-trigger {
        width: 3rem !important;
        margin: 0 !important;
        padding: 12px 8px 12px 0;
        .pi.pi-chevron-down {
            font-size: 20px;
        }
    }
    .distance-field {
        .is-disable {
            .p-inputnumber-input {
                color: #344054;
                background-color: #EAECF0 !important;
                opacity: 1 !important;
            }
        }
        span.p-inputnumber.p-component {
            width: 100%;
            height: 44px;
            &:hover {
                border-color:#84caff !important;
            }
        }
        .p-inputnumber-input {
            min-height: unset;
            border-radius: 0.5rem 0 0 0.5rem;
            &:hover {
                border-color:#84caff !important;
            }
        }
        .p-dropdown.p-component {
            border-radius: 0 0.5rem 0.5rem 0;
            height: 44px !important;
        }
    }
}

// **********************Input phone number *******************
.input-phone-booking {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    height: 44px;
    font-size: 14px;
    line-height: 20px;
    outline: none;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    .input-phone-booking .p-inputtext {
        width: 100%;
        height: 42px;
        min-height: 42px;
        padding: 12px 4px 12px 16px;
        border: none !important;
    }
    .p-dropdown:not(.p-disabled).p-focus {
        border: none !important;
        box-shadow: none;
    }
    .p-dropdown {
        border: none !important;
    }
}

.input-phone-booking .p-inputtext {
    width: 100%;
    height: 42px;
    min-height: 42px;
    padding: 12px 4px 12px 12px;
    border: none;
    outline: none;
    border-radius: 0px 8px 8px 0px;
    &:focus {
        border: none !important;
        outline: none;
        box-shadow: none;
    }
}

// Custom styles for tag p-dropdown
.input-phone-booking .p-dropdown {
    padding: 0px !important;
    display: flex;
    align-items: center;
    width: 98px;
    height: 40px;
    border: 1px solid transparent;
}

.input-phone-booking .p-dropdown-panel {
    min-width: 240px;
}

// OTA
.ota-custom {
    .custom-input {
        .p-inputtext.p-component {
            font-family: 'Inter';
            height: 44px;
            min-height: 44px;
            border-radius: 8px;
            border: 1px solid #d0d5dd;
            padding: 12px 16px;
            height: 44px;
            font-size: 14px;
            line-height: 20px;
            outline: none;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            &.is-disable {
                color: #344054;
                background-color: #EAECF0 !important;
                opacity: 1 !important;
            }
            &.is-invalid {
                border: 1px solid #dc3545 !important;
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                }
                &:hover {
                    border: 1px solid #dc3545 !important;
                }
            }
            &:hover {
                border: 1px solid #84caff !important;
            }
        }
        .unit-custom {
            .p-dropdown {
                padding: 4px;
                border-radius: 0px 8px 8px 0;
                .p-dropdown-label {
                    font-weight: 600 !important;
                }
            }
        }
        .p-dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 44px;
            padding: 4px 16px 4px 4px;
            border-radius: 8px;
            .p-dropdown-item {
                border-radius: 6px;
                color: #344054 !important;
                font-weight: 400 !important;
                &.p-highlight {
                    background-color: #f2f4f7;
                }
            }
            .p-dropdown-items-wrapper {
                padding: 4px;
                font-family: Inter, sans-serif;
                font-size: 14px;
                line-height: 20px;
                scrollbar-width: thin;
                scrollbar-color: #888 #f2f2f2;
                &::-webkit-scrollbar {
                    width: 6px !important;
                }
            }
            .p-dropdown-label {
                min-height: 44px;
                &.p-inputtext {
                    color: #344054;
                    font-family: Inter, sans-serif;
                    font-size: 14px;
                    line-height: 20px;
                }
                &.p-placeholder {
                    color: #98a2b3;
                    font-weight: 400;
                    min-height: 44px;
                }
                div {
                    justify-content: flex-start;
                }
            }
        }
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
        .p-dropdown-sort .p-dropdown {
            height: 38px;
            padding: 0 16px 0 8px;
            .p-inputtext {
                padding: 0 8px;
                min-width: 116px;
                min-height: 20px;
                line-height: 20px;
            }
            .p-dropdown-item {
                padding: 12px;
            }
        }
        .is-invalid {
            .p-dropdown.p-component {
                border: 1px solid #dc3545 !important;
                transition: all 0.3s;
                &.p-dropdown-open,
                &.p-focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                }
            }
        }
        .p-radiobutton {
            width: 20px;
            height: 20px;
            transition: all 0.3s;
            &.p-radiobutton-checked .p-radiobutton-box {
                border: 1px solid #1570ef;
                background: #eff8ff;
            }
            .p-radiobutton-box {
                width: 20px;
                height: 20px;
                background: #fff;
                border: 1px solid #d0d5dd;
                &.p-focus,
                &:hover {
                    border: 1px solid #1570ef;
                    box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
                }
                .p-radiobutton-icon {
                    width: 8px;
                    height: 8px;
                    background: #1570ef;
                }
            }
        }
        .is-invalid .p-radiobutton-box {
            border: 1px solid #dc3545;
        }
        .p-inputgroup .p-inputtext {
            border-radius: 8px 0px 0px 8px !important;
        }
        .hide-dropdown-header .p-dropdown-header {
            display: none;
        }
        &.custom-h-400 {
            .p-dropdown-items-wrapper {
                max-height: 408px !important;
            }
        }

        // hotel-management/payment-info
        &.dropdown-payment-disable {
            .p-dropdown.p-disabled {
                background-color: #f2f4f7;
                opacity: 1;
            }
        }
    }
    .checkbox-disabled {
        .p-checkbox-box.p-disabled {
            background-color: #667085;
        }
    }
    .invalid {
        .p-calendar {
            border: 1px solid red;
            border-radius: 8px;
        }
    }
    .custom-radio {
        .p-radiobutton {
            height: 20px !important;
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 300s ease-in-out 0s;
    }
}

// ********************** Rate plans *******************
.rate-plans-section {
    .p-datatable .p-datatable-thead>tr>th {
        font-weight: 500;
        line-height: 20px;
        height: 40px;
        padding: 0 12px;
        background-color: #F9FAFB;
    }
    .p-datatable .p-datatable-tbody>tr>td {
        font-weight: 500;
        line-height: 20px;
        height: 60px;
        padding: 4px 12px;
    }
    .p-inputswitch {
        width: 36px;
        height: 20px;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch .p-inputswitch-slider {
        background: #F2F4F7;
        &:hover {
            background: #F2F4F7;
        }
    }
    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        left: 0.15rem;
        margin-top: -8px;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(1rem);
    }
    .p-datatable .p-datatable-header {
        padding: 16px 16px;
    }
    .p-datatable .p-datatable-header {
        border: 1px solid #EAECF0;
        border: 1px 1px 0 1px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background-color: #fff;
    }
    .p-inputtext {
        min-height: 44px;
        &:hover {
            border-color: #D0D5DD;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        display: flex;
    }
    .p-multiselect {
        width: 100% !important;
        color: #344054;
        /* body/default/regular */
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #D0D5DD;
        }
    }
    .p-multiselect-items-wrapper {
        max-height: 400px !important;
        padding-right: 4px !important;
        padding-left: 4px !important;
    }
    .rate-plans-section .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        border-radius: 8px;
        box-shadow: none;
        &:hover {
            background-color: #F2F4F7;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px !important;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        background: #fff;
        box-shadow: none;
        border-radius: 8px;
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
        box-shadow: none;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        background: #F2F4F7;
        border-radius: 8px;
    }
    .p-multiselect-header {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: #F2F4F7;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
        .p-multiselect-close{
            display: none;
        }
        .p-multiselect-filter-container {
            display: flex;
           // justify-content: space-between !important;
           // flex-direction: row-reverse !important;
            .p-multiselect-filter-icon{
                right: auto;
                left: 16px;
            }
            .p-inputtext{
                padding-left: 45px;
            }
        }
        // .p-multiselect-filter-container .p-multiselect-filter-icon {
        //     right: 0.75rem;
        //     color: #6c757d;
        // }
        // // .p-multiselect-filter .p-inputtext {
        //     height: 44px !important;
        //     min-height: 44px !important;
        // }
    }
    .p-multiselect:not(.p-disabled).p-focus {
        //box-shadow: none;
        border: 1px solid #84CAFF !important;
        background: var(--base-white, #FFF);
        box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
    }
    .p-paginator {
        padding: 0px;
        height: 52px;
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-last {
        display: none;
    }
    .p-paginator .p-dropdown {
        display: none;
    }
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        height: 36px !important;
        min-width: 36px !important;
        background-color: #fff;
        color: #1570EF;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        height: 36px;
        min-width: 36px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
    }
}

.rate-plans-modal-section {
    .p-component-overlay {
        background-color: rgba(0, 0, 0.2, 0.6) !important;
    }
    .p-dialog .p-dialog-header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
        padding-bottom: 2px;
    }
    .p-dialog .p-dialog-content {
        background: #fff !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .p-dialog-header {
        justify-content: flex-start !important;
        position: relative;
    }
    .p-dialog .p-dialog-header-icons {
        position: absolute;
        right: 20px;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }
    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.customize-rate-plan {
  .field-checkbox {
    margin-bottom: 0 !important;
  }

  // checkbox
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #D0D5DD;
    background-color: #F2F4F7;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #1570EF;
    background: #1570EF;
    color: #fff;
  }

  .p-checkbox .p-checkbox-box {
    width: 20px;
    height: 20px;
    border: 1px solid #D0D5DD;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px !important;
  }

  .p-checkbox {
    width: 20px !important;
    height: 20px !important;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #fff;
    font-weight: 600;
    font-size: 12px;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #1570EF;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: #1570EF;
    box-shadow: none;
  }

  .p-progressbar-label {
    display: none !important
  }
  .p-progressbar .p-progressbar-value {
    background-color: #1570EF !important;
    border-radius: 8px;
  }

  .p-multiselect {
    width: 100% !important;
    color: #344054;
    /* body/default/regular */
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid #D0D5DD;

    &:hover {
      border-color: #D0D5DD;
    }
  }

  .p-multiselect-items-wrapper {
    max-height: 400px !important;
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    border-radius: 8px;
    box-shadow: none;

    &:hover {
      background-color: #F2F4F7;
    }
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    color: #344054;
    font-family: 'Inter' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
    padding: 12px !important;


  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: #fff;
    box-shadow: none;
    border-radius: 8px;
    color: #344054;
    font-family: 'Inter' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    background: #F2F4F7;
    border-radius: 8px;
  }

  .p-multiselect:not(.p-disabled).p-focus {
    //box-shadow: none;
    border: 1px solid #84CAFF !important;
    background: var(--base-white, #FFF);
    box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
  }

  // Radio button
  .p-radiobutton .p-radiobutton-box {
    width: 20px;
    height: 20px;
    border: 1px solid #D0D5DD;

    &:hover {
      border-color: #1570EF !important;
    }
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #EFF8FF;
    border-color: #1570EF;


    &:hover {
     background: #EFF8FF;
    }
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon  {
    background-color: #1570EF;
    width: 8px;
    height: 8px;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #1570EF;
    background: #EFF8FF;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none !important;
  }

  /// dropdown
  .is-invalid .p-dropdown {
    border: 1px solid #dc3545 !important;
    &.p-focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
    }
  }
  .p-dropdown .p-dropdown-trigger{
    width: 1.25rem !important;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    height: 44px !important;
  }

  .p-dropdown-label.p-inputtext {
    &.p-placeholder {
      color: #98a2b3;
      font-weight: 300;
      min-height: 44px;
      font-size: 14px;
    }
    div {
      justify-content: flex-start;
      font-family: Inter, sans-serif;
    }
  }
  .p-dropdown-header {
    display: none;
  }
  .p-dropdown-items-wrapper {
    padding: 4px;
    font-family: Inter, sans-serif;
    max-height: 408px !important;

    .p-dropdown-item {
      color: #344054;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-overflow: ellipsis;

      &.p-highlight {
        color: #344054;
        background-color: #f2f4f7;
      }
      &:hover {
        background: #f2f4f7 !important;
      }
    }
  }
}


// ********************** Standard rate *******************
.standard-rate {
    .p-datatable {
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-radius: 8px !important;
        .p-datatable-header {
            border-radius: 8px 8px 0 0;
            background: #fff;
        }
        .p-datatable-wrapper {
            border-radius: 0 0 8px 8px;
        }
    }
    .p-datatable-thead {
        tr {
            th {
                font-family: 'Inter', 'sans-serif';
                font-size: 14px;
                font-weight: 500;
                color: #344054;
                line-height: 20px;
                background: #f9fafb;
                padding: 0 12px;
                height: 40px;
            }
        }
    }
    .p-datatable-tbody {
        tr {
            td {
                font-family: 'Inter', 'sans-serif';
                padding: 4px 12px;
                height: 60px;
            }
        }
    }
}

.standard-rate-modal {
    .p-dialog-title {
        font-family: 'Inter', 'sans-serif';
    }
    .custom-input {
        .p-inputtext.p-component {
            height: 44px;
            min-height: 44px;
            border: 1px solid #d0d5dd;
            font-size: 14px;
            line-height: 20px;
            outline: none;
            font-family: 'Inter', 'sans-serif';
            &:focus {
                border: 1px solid #d0d5dd !important;
                box-shadow: none;
            }
        }
    }
}

// ********************** List view property management *******************
.list-view-property-management-section {
    .p-datatable>.p-datatable-wrapper {
        border: 1px solid #EAECF0;
        border-radius: 8px;
    }
    .p-datatable .p-datatable-thead>tr>th {
        font-weight: 500;
        line-height: 20px;
        height: 40px;
        padding: 0 12px;
        background-color: #F9FAFB;
    }
    .p-datatable .p-datatable-tbody>tr>td {
        font-weight: 400;
        line-height: 20px;
        height: 56px;
        padding: 4px 12px;
    }
    .p-datatable .p-datatable-header {
        padding: 16px 16px;
    }
    .p-datatable .p-datatable-header {
        border: 1px solid #EAECF0;
        border: 1px 1px 0 1px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background-color: #fff;
    }
    .p-datatable .p-datatable-tbody>tr {
        &:hover {
            background-color: #F9FAFB !important;
        }
    }
    .p-datatable .p-datatable-tbody>tr>td {
        border: 1px solid #EAECF0;
        border-width: 0 0 1px 0;
    }
    .p-datatable .p-datatable-thead>tr>th {
        border: 1px solid #EAECF0;
        border-width: 0 0 1px 0;
    }
  }

  .p-datatable .p-datatable-tbody > tr > td {
    border: 1px solid #EAECF0;
    border-width: 0 0 1px 0;
  }

  .p-datatable .p-datatable-thead > tr > th {
    border: 1px solid #EAECF0;
    border-width: 0 0 1px 0;
  }


// ********************** Calendar *******************
.calendar-dropdown {
  .p-dropdown {
    height: 36px;
    width: 200px;
    align-items: center;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    height: 36px;
    padding: 8px 0 8px 16px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .p-inputtext {
    min-height: 0;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #344054;
  }

  .p-dropdown .p-dropdown-trigger {
    margin-right: 16px;
  }
}

.calendar-dropdown-right {
    .p-dropdown {
      height: 44px;
      width: 100%;
      align-items: center;
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
      height: 36px;
      padding: 9px 0 8px 16px;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .p-inputtext {
      min-height: 0;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #344054;
    }

    .p-dropdown .p-dropdown-trigger {
      margin-right: 16px;
    }

    .p-disabled {
        background-color: #F2F4F7;
    }

    .p-overlay {
        left: -1px !important;
    }
}

.calendar-from-input-booking {
    .p-calendar-w-btn .p-inputtext {
        min-height: 0;
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: none;
        border-left: 1px solid #D0D5DD;
    }

    .p-calendar-w-btn .p-datepicker-trigger {
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: 1px solid #D0D5DD;
        border-left: none;
    }

    .p-button {
        background: var(--base-white, #FFF);
        color: #D0D5DD;
    }

    .p-button:enabled:hover {
        background: var(--base-white, #FFF);
        color: #D0D5DD;
    }

    @media (max-width: 1800px) {
        .p-calendar .p-datepicker {
            width: 100%;
        }

        .p-calendar {
            width: 100% !important;
        }
    }

    .p-button:focus {
        box-shadow: none;
    }

    .p-datepicker table td {
        padding: 1px;
    }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: #1570EF;
        color: white;
    }

    .p-datepicker table td > span.p-highlight {
        border: 1px solid #1570EF;
        background-color: rgb(239 248 255);
        color: #1570EF;
    }

    .p-inputtext:focus {
        box-shadow: none;
    }

    .p-inputtext:enabled:focus {
        border: 1px solid #D0D5DD !important;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none !important;
    }
}

.calendar-to-input-booking {
    .p-calendar-w-btn .p-inputtext {
        min-height: 0;
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: none;
        border-left: 1px solid #D0D5DD;
    }

    .p-calendar-w-btn .p-datepicker-trigger {
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: 1px solid #D0D5DD;
        border-left: none;
    }

    .p-button {
        background: var(--base-white, #FFF);
    }

    .p-button:enabled:hover {
        background: var(--base-white, #FFF);
        color: #D0D5DD;
    }

    .p-button:focus {
        box-shadow: none;
    }

    .p-datepicker table td {
        padding: 1px;
    }

    @media (max-width: 1800px) {
        .p-calendar .p-datepicker {
            left: 0px !important;
            width: 100%;
        }

        .p-calendar {
            width: 100% !important;
        }
    }

    @media (min-width: 1800px) and (max-width: 1920px) {
        .p-calendar .p-datepicker {
            left: -150px !important;
        }
    }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: #1570EF;
        color: white;
    }

    .p-datepicker table td > span.p-highlight {
        border: 1px solid #1570EF;
        background-color: rgb(239 248 255);
        color: #1570EF;
    }

    .p-inputtext:focus {
        box-shadow: none;
    }

    .p-inputtext:enabled:focus {
        border: 1px solid #D0D5DD !important;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none !important;
    }
}

.calendar-input-text-booking {
    .p-input-icon-left > .p-inputtext {
        height: 44px;
        min-height: 0;
        width: 100%;
    }

    .p-input-icon-left > i:first-of-type {
        top: 19px;
    }

    .p-inputtext:enabled:hover {
        border-color: #84CAFF;
    }

    .p-input-icon-left > .p-inputtext {
        padding-left: 33px;
    }
}

// CUSTOM POPUP
p-confirmdialog.custom-popup {
    .p-dialog {
        width: 544px;

        .p-dialog-header {
            font-family: Inter;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            color: #101828;

            .p-dialog-header-icons {
                .p-dialog-header-close {
                    span {
                        font-size: 20px;
                        color: #667085;
                    }
                }
            }
        }

        .p-dialog-content {
            background-color: white !important;
            padding: 0 1.5rem 1.5rem !important;

            .p-confirm-dialog-message {
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin: unset;
                color: #475467;
            }
        }

        .p-dialog-footer {
            button {
                height: 44px;
                font-family: Inter;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                outline: none;

                &:hover {
                    background-color: unset;
                    opacity: .8;
                }

                .p-button-icon {
                    display: none;
                }

                span {
                    font-weight: 600;
                }
            }

            button.btn-cancel-reject {
                color: #344054;
                border: 1px solid #D0D5DD;
                background: var(--base-white, #FFF) !important;
            }

            button.btn-cancel-accept {
                color: #FFFFFF;
                background-color: #D92D20;
                border: none;
            }

            button.btn-save-as-draft-reject {
                color: #344054;
                border: 1px solid #D0D5DD;
                background: var(--base-white, #FFF) !important;
            }

            button.btn-warning {
                background-color: #f04438;
                border: 1px solid #D0D5DD;
            }

            button.btn-save-as-draft-accept {
                color: #FFFFFF;
                background-color: #1570EF;
                border: none;
            }
            button.btn-back-accept {
                color: #344054;
                border: 1px solid #D0D5DD;
            }

            button.btn-back-reject {
                color: #FFFFFF;
                background-color: #1570EF;
                border: none;
            }
        }
    }
}

p-confirmdialog.custom-back-popup .p-dialog-footer{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

// ********************** Rate plans *******************
.user-management-section {
    .p-datatable .p-datatable-thead>tr>th {
        font-weight: 500;
        line-height: 20px;
        height: 40px;
        padding: 0 12px;
        background-color: #F9FAFB;
    }
    .p-datatable .p-datatable-tbody>tr>td {
        font-weight: 500;
        line-height: 20px;
        height: 60px;
        padding: 4px 12px;
    }
    .p-inputswitch {
        width: 36px;
        height: 20px;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch .p-inputswitch-slider {
        background: #F2F4F7;
        &:hover {
            background: #F2F4F7;
        }
    }
    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        left: 0.15rem;
        margin-top: -8px;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(1rem);
    }
    .p-datatable .p-datatable-header {
        padding: 16px 16px;
    }
    .p-datatable .p-datatable-header {
        border: 1px solid #EAECF0;
        border: 1px 1px 0 1px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background-color: #fff !important;
    }
    .p-inputtext {
        min-height: 44px;
        &:hover {
            border-color: #D0D5DD;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        display: flex;
    }
    .p-multiselect {
        width: 100% !important;
        color: #344054;
        /* body/default/regular */
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
       // height: 44px;
        min-height: 44px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #D0D5DD;
        }
    }
    .p-multiselect-items-wrapper {
        max-height: 400px !important;
        padding-right: 4px !important;
        padding-left: 4px !important;
    }
    .rate-plans-section .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        border-radius: 8px;
        box-shadow: none;
        &:hover {
            background-color: #F2F4F7;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px !important;
        height: 44px;
        min-height: 44px;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        background: #fff;
        box-shadow: none;
        border-radius: 8px;
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        height: 44px;
        min-height: 44px;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
        box-shadow: none;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        background: #F2F4F7;
        border-radius: 8px;
    }
    .p-multiselect-header {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: #F2F4F7;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
        .p-multiselect-close{
            display: none;
        }
        .p-multiselect-filter-container {
            display: flex;
            .p-multiselect-filter-icon{
                right: auto;
                left: 16px;
            }
            .p-inputtext{
                padding-left: 45px;
            }
        }
    }
    .p-multiselect:not(.p-disabled).p-focus {
        //box-shadow: none;
        border: 1px solid #84CAFF !important;
        background: var(--base-white, #FFF);
        box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
    }
    .p-multiselect-label{
        white-space: normal;
    }
    .p-multiselect .p-multiselect-label.p-placeholder{
        color: #98A2B3 !important;
    }
    .p-paginator {
        padding: 0px;
        height: 52px;
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-last {
        display: none;
    }
    .p-paginator .p-dropdown {
        display: none;
    }
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        height: 36px !important;
        min-width: 36px !important;
        background-color: #fff;
        color: #1570EF;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        height: 36px;
        min-width: 36px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
    }
    .p-dropdown{
        display: flex;
        align-items: center;
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 20px;
        .p-dropdown-trigger {
            width: unset !important;
            padding: 10px 16px 10px 0;
        }
        .p-dropdown-label .p-inputtext .p-placeholder{
            color: #98A2B3 !important;
        }
    }
    .p-dropdown .p-dropdown-label.p-placeholder{
        color: #98A2B3 !important;
    }
}

.user-modal-section {
    .p-component-overlay {
        background-color: rgba(0, 0, 0.2, 0.6) !important;
    }
    .p-dialog .p-dialog-header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
        padding-bottom: 2px;
    }
    .p-dialog .p-dialog-content {
        background: #fff !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .p-dialog-header {
        justify-content: flex-start !important;
        position: relative;
    }
    .p-dialog .p-dialog-header-icons {
        position: absolute;
        right: 20px;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }
    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

// ********************** Property Policies *******************
.property-policies {
    .form-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #344054;
        &.form-require {
            color: #d92d20;
        }
        }

    .form-input {
        font-size: 14px;
        border: 1px solid #d0d5dd;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 8px;

        &:focus {
        border: 1px solid #84caff;
        box-shadow: 0px 0px 0px 4px #eaf3ff, 0px 1px 2px 0px #84caff;
        }

        &.is-invalid {
        border: 1px solid #dc3545 !important;

        &:focus {
            border: 1px solid #dc3545 !important;
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        }
        }

        &:hover {
        border: 1px solid #84caff;
        }
    }

    .form-disable {
        font-size: 14px;
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        background-color: #F2F4F7;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        &.p-component:disabled {
          opacity: 1 !important;
        }
        .p-inputgroup-addon {
          border-left: 1px !important;
        }
        .p-dropdown{
          width: 100% !important;
          border: 1 #d0d5dd !important;
          box-shadow: none;
          min-height: 42px;
          height: 42px;
          background-color: #F2F4F7;
          &.p-disabled {
              opacity: 1 !important;
          }
        }
        &:hover {
          border: 1px solid #d0d5dd !important;
          }
      }

    .read-only {
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        padding: 12px 16px;
        height: 44px;
        font-size: 14px;
        line-height: 20px;
        outline: none;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        color: #344054 !important;
        cursor:default;
    }

    .input-time {
        padding-left: 40px !important;
    }

    .p-inputtext {
        min-height: 44px;
        font-family: 'Inter';
        font-size: 14px !important;
    }

    .p-inputtext:enabled:focus {
      border-radius: 8px;
    }

    .p-inputgroup {
        border-radius: 0;
    }

    .p-inputgroup-addon {
        border-left: 0px !important;
    }

    //dropdown
    .p-dropdown {
        box-shadow: none !important;
        border: none !important;
        border-left: 0px;
        min-height: 42px;
        height: 42px;
        width: 100% !important;
        .p-inputgroup-addon {
            border-left: 1px !important;
        }
    }

    .p-dropdown .p-dropdown-trigger {
      margin-right: 6px;
    }
    .p-dropdown:not(.p-disabled).p-focus {
      border: none !important;
      border-radius: none !important;
    }

    .p-dropdown,
    .p-dropdown-label .p-inputtext {
        align-items: center;
        width: 160px;
        min-height: 42px;
        height: 42px;
        font-size: 14px !important;
        font-family: 'Inter';
        outline: none;
        color: #344054;

        &.p-placeholder {
          font-size: 14px;
          color: #98a2b3;
          font-weight: 400;
          min-height: 42px;
          height: 42px;
          border: none;
        }
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
        font-size: 14px;
        color: #98A2B3 !important;
        font-weight: 400;
        min-height: 42px;
        height: 42px;
        border: none;
    }

    .p-dropdown-panel{
        border: 1px solid #D0D5DD;
    }

    //switch control
    .small-input-switch {
      .p-inputswitch {
        height: 1.25rem;
        width: 2.25rem;
        &.p-inputswitch-checked {
            .p-inputswitch-slider::before {
              transform: translateX(0.9rem);
            }
            .p-inputswitch-slider {
              background: #1570EF;
            }
        }
          &.p-inputswitch-checked:not(.p-disabled):hover {
          .p-inputswitch-slider {
            background: #1570EF;
          }
        }
        &.p-focus .p-inputswitch-slider {
          box-shadow: 0 0 0 0.2rem #d1e9ff;
        }
        .p-inputswitch-slider::before {
            width: 0.9rem;
            height: 0.9rem;
            margin-top: -0.45rem;
        }
      }
    }

    //input number
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        appearance: textfield;
    }

    //text area
    textarea {
        resize: none;
    }
    .text-disable{
        color: #A2D4FB;
    }

    // Radio button
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }

    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #1570EF;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #1570EF;
        background: #1570EF;
        color: #fff;
    }
    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 4px !important;
    }

    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #fff;
        font-weight: 600;
        font-size: 12px;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #1570EF;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #1570EF;
        box-shadow: none;
    }

    .pay-ment .p-checkbox .p-checkbox-box.p-highlight{
        background-color: #B2DDFF !important;
        border-color: #B2DDFF !important;
    }

     .ng-trigger.ng-trigger-animation {
        max-width: 400px !important;
        width: 400px !important;
    }
}

.promotion-section {
    .p-inputtext.p-component{
        &:hover {
            border: 1px solid #84caff !important;
        }
    }
    .p-inputtext:enabled:hover{
        border: 1px solid #84caff !important;
        }

    .p-datatable .p-datatable-thead>tr>th {
        font-weight: 500;
        line-height: 20px;
        height: 40px;
        padding: 0 12px;
        background-color: #F9FAFB;
    }
    .p-datatable .p-datatable-tbody>tr>td {
        font-weight: 500;
        line-height: 20px;
        height: 60px;
        padding: 4px 12px;
    }
    .p-inputswitch {
        width: 36px;
        height: 20px;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }
    .p-inputswitch .p-inputswitch-slider {
        background: #F2F4F7;
        &:hover {
            background: #F2F4F7;
        }
    }
    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        left: 0.15rem;
        margin-top: -8px;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(1rem);
    }
    .p-datatable .p-datatable-header {
        padding: 16px 16px;
    }
    .p-datatable .p-datatable-header {
        border: 1px solid #EAECF0;
        border: 1px 1px 0 1px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background-color: #fff;
    }
    .p-inputtext {
        min-height: 44px;
        &:hover {
            border-color: #D0D5DD;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        display: flex;
    }
    .p-multiselect {
        width: 100% !important;
        color: #344054;
        /* body/default/regular */
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #D0D5DD;
        }
    }
    .p-multiselect-items-wrapper {
        max-height: 400px !important;
        padding-right: 4px !important;
        padding-left: 4px !important;
    }
    .rate-plans-section .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        border-radius: 8px;
        box-shadow: none;
        &:hover {
            background-color: #F2F4F7;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px !important;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        background: #fff;
        box-shadow: none;
        border-radius: 8px;
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
        box-shadow: none;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        background: #F2F4F7;
        border-radius: 8px;
    }
    .p-multiselect-header {
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: #F2F4F7;
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }
        .p-multiselect-close{
            display: none;
        }
        .p-multiselect-filter-container {
            display: flex;
           // justify-content: space-between !important;
           // flex-direction: row-reverse !important;
            .p-multiselect-filter-icon{
                right: auto;
                left: 16px;
            }
            .p-inputtext{
                padding-left: 45px;
            }
        }
    }
    .p-multiselect:not(.p-disabled).p-focus {
        //box-shadow: none;
        border: 1px solid #84CAFF !important;
        background: var(--base-white, #FFF);
        box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
    }
    .p-paginator {
        padding: 0px;
        height: 52px;
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-last {
        display: none;
    }
    .p-paginator .p-dropdown {
        display: none;
    }
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        height: 36px !important;
        min-width: 36px !important;
        background-color: #fff;
        color: #1570EF;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        height: 36px;
        min-width: 36px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
    }
    .form-input {
        font-size: 14px;
        border: 1px solid #d0d5dd;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

        &:focus {
          border: 1px solid #84caff;
          box-shadow: 0px 0px 0px 4px #eaf3ff, 0px 1px 2px 0px #84caff;
        }

        &:hover {
          border: 1px solid #84caff !important;
        }

        &:enabled:hover  {
            border: 1px solid #84caff !important;
          }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type="number"] {
          appearance: textfield;
        }
        .p-calendar{
            width: 100% !important;
        }
    }
    .p-input-icon-right>i {
        top: 0% !important
    }
}

.radio-button-promotion{
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF !important;
        background: #EFF8FF !important;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
}

.calendar-filter-discount {
    .p-slider:not(.p-disabled) .p-slider-handle:hover{
        border-color: #1570EF !important;
        background: #1570EF !important;
    }
    .p-slider .p-slider-handle{
        height: 24px;
        width: 24px !important;
        border-color: #1570EF !important;
        top: -10% !important;
    }
    .p-slider .p-slider-range{
        align-items: center !important;
        height: 8px !important;
        background: #1570EF !important;
    }
    .p-slider-horizontal .p-slider-range{
        top: -2px !important;
    }
}

.calendar-filter-from {
    .p-calendar .p-datepicker {
        top: 50px !important;
        left: -131px !important;
    }
    .p-inputtext{
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #d0d5dd;
        font-family: Inter, sans-serif;
        font-size: 14px;
    }
    .p-inputtext:enabled:hover{
        border: 1px solid #84caff;
    }
    .p-inputtext:enabled:focus{
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #84caff;
        font-weight: 400;
    }
    .p-calendar{
        width: 100% !important;
    }
}

.calendar-filter-to {
    .p-calendar .p-datepicker {
        top: 50px !important;
        left: -174px !important;
    }
    .p-inputtext{
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #d0d5dd;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
    .p-inputtext:enabled:hover{
        border: 1px solid #84caff;
    }
    .p-inputtext:enabled:focus{
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #84caff;
    }
    .p-calendar{
        width: 100% !important;
    }
}

.calendar-promotions {
    .p-datepicker{
        padding: 16px;
        padding-bottom: 16px;
    }
    .p-datepicker table td {
        padding: 0 !important;
        font-family: 'Inter' !important;
    }

    .p-datepicker table td > span.p-highlight {
        color: white;
        background: #1570EF;
    }

    .p-datepicker table tbody {
        @apply space-y-1
    }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: white;
        color: #1570EF;
    }

    .p-datepicker table td > span:focus {
        box-shadow: none;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
        box-shadow: none;
    }

    .p-datepicker .p-datepicker-header {
        border-bottom: none;
    }

    .p-datepicker-month,
    .p-datepicker-year{
        color: #344054;
    }
    .p-datepicker-month:enabled:hover,
    .p-datepicker-year:enabled:hover
    {
        color: #1570EF !important;
    }

    .p-link:focus{
        box-shadow: none !important;
    }

    .p-datepicker-prev:focus{
        box-shadow: none !important;
    }

    .p-datepicker-header{
        padding: 0;
    }
    .p-monthpicker-month:not(.p-disabled):focus{
        box-shadow: none !important;
    }
    .p-yearpicker-year:not(.p-disabled):focus {
        box-shadow: none !important;
    }
    .p-yearpicker-year.p-highlight{
        color: #1570EF !important;
    }
    .p-input-icon-right>i {
        top: 25% !important
    }
}



.promotions-modal-section {
    .p-component-overlay {
        background-color: rgba(0, 0, 0.2, 0.6) !important;
    }
    .p-dialog .p-dialog-header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
        padding-bottom: 2px;
    }
    .p-dialog .p-dialog-content {
        background: #fff !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .p-dialog-header {
        justify-content: flex-start !important;
        position: relative;
    }
    .p-dialog .p-dialog-header-icons {
        position: absolute;
        right: 20px;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }
    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }
    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

// ********************** Promotion stay date *******************
.calendar-stay-dates {
    .p-datepicker {
        padding: 12px;
    }

    .p-datepicker table td > span {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
        padding-right: 12px;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
        padding-left: 12px;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-right: 0;
        padding-right: 0;
    }

    .p-datepicker table th > span {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .p-datepicker table td {
        padding: 0 !important;
    }

    .p-datepicker table td > span.p-highlight {
        color: black;
        background: none;
    }

    .p-datepicker table tbody {
        @apply space-y-1
    }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: white;
        color: #1570EF;
    }

    .p-datepicker table td > span:focus {
        box-shadow: none;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
        box-shadow: none;
    }

    .p-datepicker .p-datepicker-header {
        border-bottom: none;
    }
}

.input-calendar-stay-dates {
    .p-inputtext {
        min-height: 0;
        height: 44px;
    }

    .p-inputtext:enabled:hover {
        border-color: #84CAFF;
    }

    .p-input-icon-right>i {
        top: 45%;
    }
}

.input-calendar-bookable {
    .p-datepicker {
        display: flex;
    }

    .p-inputtext {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .p-calendar .p-datepicker {
        top: -395px !important;
        left: -86% !important;
    }

    .p-datepicker table td > span {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        pointer-events: none;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-right: 0;
        padding-right: 0;
        pointer-events: none;
    }

    .p-datepicker table th > span {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .p-calendar {
        width: 100%;
    }

    .p-timepicker {
        display: none;
    }

    .time-picker-bookable {
        width: 200px;
    }

    .p-calendar-w-btn .p-inputtext {
        min-height: 0;
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: none;
        border-left: 1px solid #D0D5DD;
    }

    .p-calendar-w-btn .p-datepicker-trigger {
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: 1px solid #D0D5DD;
        border-left: none;
    }

    .p-button:enabled:hover {
        background: none;
        color: #D0D5DD;
    }

    .p-button:focus {
        box-shadow: none;
    }

    .p-datepicker table td {
        padding: 1px;
    }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: #fff;
        color: #1570EF;
    }

    .p-datepicker table td > span.p-highlight {
        border: 1px solid #1570EF;
        background-color: #1570EF;
        color: white;
    }

    .p-inputtext:focus {
        box-shadow: none;
    }

    .p-inputtext:enabled:focus {
        border: 1px solid #D0D5DD !important;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none !important;
    }
}
.custom-button-badge {
    .p-button-text{
        padding: 0;
    }

    .p-button-label{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        color: #667085;
        padding: var(--Spacing-2, 8px) var(--Spacing-3, 12px);
        border-radius: var(--Border-Radius-4, 4px);
    }
    &.clicked {
        .p-button-label{
            background-color: #EFF8FF;
            color: #1570EF;
        }
        .p-button .p-badge{
            background-color: #EFF8FF;
            color: #1570EF;
        }
    }
    .p-button .p-badge{
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: #344054;
        border-radius: var(--Border-Radius-Full, 1000px);
        background: var(--Color-Background-System-Secondary, #F2F4F7);
    }

}
.manage-reservations {
    app-tag {
        display: inline-table;
        padding: 0.5rem 1.5rem;
        .error{
            color: #344054;
            background-color: #F2F4F7;
        }
        .info{
            color: #1570EF;
            background-color: #EFF8FF;
        }
        .warning{
            color: #DC6803;
            background-color: #FFFAEB;
        }
        .success{
            color: #039855;
            background-color: #ECFDF3;
        }
        .normal {
            color: #6938EF;
            background-color: #F4F3FF;
        }
      }
}
body.popup-open {
    overflow: hidden;
}


.flight-search-bar{
    // radiobutton
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        &:hover {
            border-color: #1570EF !important;
        }
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;
        &:hover {
            background: #EFF8FF;
        }
    }
    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF !important;
        background: #EFF8FF !important;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }
    // check box
    .form-input {
        font-size: 14px;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 8px;
    }
    .form-input:has(.box-input:focus) {
        border: 1px solid #84caff;
        box-shadow: 0px 0px 0px 4px #eaf3ff, 0px 1px 2px 0px #84caff;
    }

    .form-input:has(.box-input:hover) {
        border: 1px solid #84caff;
    }

    .box-input {
        font-size: 14px;
        width: 100%;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 8px;

        &:focus {
        border: none;
        box-shadow: none;
        }

        &.is-invalid {
        border: none;
        }
    }
}


.language-setting {
  @apply w-[480px];
  .cancel-language-btn .p-button {
    @apply text-gray-700 items-center text-base bg-white border-gray-300;
  }
  .cancel-language-btn .p-button:enabled:hover {
    @apply text-gray-700 items-center text-base bg-white border-gray-300;
  }
  .confirm-language-btn .p-button {
    @apply text-white items-center text-base bg-blue-600;
  }
  .confirm-language-btn .p-button:enabled:hover {
  }
}
.sign-out-dialog {
  .p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  .p-dialog-content {
    padding: 8px !important;
    background-color: white !important;
  }
  .p-dialog-content > div {
    padding: 8px !important;
  }
  .cancel-sign-out-btn {
    width: 50%;
    .p-button {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-gray-700 py-2.5 justify-center;
    }
    .p-button:enabled:hover {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-gray-700 py-2.5 justify-center;
    }
  }
  .confirm-sign-out-btn {
    width: 50%;
    .p-button {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-white bg-blue-700 py-2.5 justify-center;
    }
    .p-button:enabled:hover {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-white bg-blue-700 py-2.5 justify-center;
    }
  }
}
.create-account {
  .p-dialog-content {
    padding: 0px;
  }
  .create-acc-text {
    @apply text-sm text-gray-500;
  }
  .cancel-create-btn {
    width: 50%;
    .p-button {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-gray-700 py-2.5 justify-center;
    }
    .p-button:enabled:hover {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-gray-700 py-2.5 justify-center;
    }
  }
  .confirm-create-btn {
    width: 50%;
    .p-button {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-white bg-blue-700 py-2.5 justify-center;
    }
    .p-button:enabled:hover {
      @apply text-base font-semibold w-[100%] border px-[18px] border-blue-700 text-white bg-blue-700 py-2.5 justify-center;
    }
  }
}

.bs-datepicker-body table td::before,
.bs-datepicker-body table td span::before {
  z-index: -11 !important;
}
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span, .bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: transparent !important;
}

.bs-datepicker-body table.days span.is-highlighted.in-range::before {
  right: 5px !important;
}

.bs-datepicker-body table.days span.in-range.select-end:before {
  right: 40px !important;
  left: 0 !important;
}

.bs-datepicker-body table td span.selected {
  z-index: 2 !important;
}

.language-setting {
  background-color: white;
  &>.p-dialog-content > div {
    padding: 0px 24px 16px;
  }
}

.t-rich-text-container {
    word-break: break-all;

    ul {
        list-style:disc;
        margin-left: 1rem;
    }
    ol {
        list-style:decimal;
        margin-left: 1rem;
    }
    h1 {
        font-size: 1.2rem;
        line-height: 1.75rem;
    }
    h2 {
        font-size: 0.7rem;
        line-height: 1rem;
    }
    blockquote {
        position: relative;
      }

    blockquote::before {
        font-size: 1.2rem;
        content: "“";
        left: -0.5rem;
      }

    blockquote::after {
        font-size: 1.2rem;
        position: absolute;
        content: "”";
      }
}
