.custom-noti-mobile > div:first-of-type {
  position: fixed;
  z-index: 9999;
}
.custom-mega-menu-mobile > div:first-of-type {
  position: fixed;
  z-index: 9999;
}
#download-app-popup {
  z-index: 9999 !important;
}
